import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        alias: ['/index.html'],
        name: 'Overview',
        component: () => import('../views/Overview.vue')
    },
    {
        path: '/content_images/:id',
        name: 'Content',
        component: () => import('../views/Content.vue')
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: () => import('../views/NotFound.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
