<template>
    <router-view/>
</template>

<style lang="scss">
    @import "css/global";

    #app {
        max-width: 750px;
        margin: 0 auto;
    }
</style>
