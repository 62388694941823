import {createStore} from 'vuex'

export default createStore({
    state() {
        return {
            contents: [
                {
                    id: "brennstoffzelle",
                    name: "Brennstoffzelle",
                    image: "brennstoffzelle.jpg",
                    image_src: "https://blog.energiedienst.de/brennstoffzellenheizung/",
                    filter: {
                        livingSituation: ['house'],
                        energyType: ['hydrogen'],
                        location: ['central'],
                        purpose: ['room', 'water'],
                        cost: ['high'],
                        isolation: ['good']
                    },
                    text: "Eine Brennstoffzellenheizung wandelt Wasserstoff zu Wasser um. Dabei wird elektrische Energie und Wärme frei. Momentan wird der Wasserstoff in einem Reformer aus Erdgas gewonnen. Aus diesem Grund ist nach momentanen Stand der Technik die Brennstoffzellenheizung keine wirkliche Alternative zum Erdgas, solange kein (über-)regionales Wasserstoffnetz verfügbar ist. Ist dies verfügbar, so kann auch die Brennstoffzelle eine Heizungsalternative darstellen. Durch die momentan noch geringe thermische Leistung ist nach jetzigem Stand vor allem die Nutzung in Niedrigenergiehäusern praktikabel. Es ist jedoch vorstellbar, dass mit fortschreitender Entwicklung höhere Leistungen realisiert werden können, sodass perspektivisch keine Spitzenlastheizung mehr notwendig ist.",
                    ups: ["emissionsfreie Heizung", "Produktion von Strom und Wärme", "dezentrale Energieerzeugung"],
                    downs: ["ohne Wasserstoffnetz keine Heizungsalternative", "momentan hohe Anschaffungskosten", "momentan geringe thermische Leistung, deshalb wird zweite Wärmequelle benötigt"]
                },
                {
                    id: "durchlauferhitzer",
                    name: "Durchlauferhitzer",
                    image: "durchlauferhitzer.jpg",
                    image_src: "https://www.stiebel-eltron.de/de/home/produkte-loesungen/warmwasser/durchlauferhitzer/komfort-durchlauferhitzer/dhe/dhe-18-21-24.html",
                    filter: {
                        livingSituation: ['house', 'flat'],
                        energyType: ['electricity'],
                        location: ['decentralized'],
                        purpose: ['water'],
                        cost: ['low'],
                        isolation: ['good', 'bad']
                    },
                    text: "Ein Durchlauferhitzer erwärmt das durchfließende Wasser mit Strom. Hier ist erwähnenswert, dass Strom ein teurer Energieträger ist und das Stromnetz durch Gleichtätigkeitseffekte stark belastet werden kann. Da das Wasser sehr schnell erhitzt wird, ist eine hohe Anschlussleistung notwendig. Die Installation sollte deshalb von Fachpersonal geplant und durchgeführt werden. Ein Durchlauferhitzer kommt ohne Speicher aus und hat deshalb keine Speicherverluste. Es kann vorkommen, dass Durchlauferhitzer und wassersparende Duschköpfe nicht kompatibel sind. Dies ist abhängig von der verbauten Technik.",
                    ups: ["dezentrale Lösung zur Wassererwärmung", "kaum Speicher- und Leitungsverluste", "geringe Anschaffungskosten"],
                    downs: ["hohe Energiekosten durch erhöhten Strombedarf", "kann das Hausnetz bei schlechter Elektroplanung überlasten"]
                },
                {
                    id: "infrarotheizung",
                    name: "Infrarotheizung",
                    image: "infrarotheizung.jpg",
                    image_src: "https://www.vaillant.de/heizung/heizung-verstehen/technologie-verstehen/infrarotheizung/",
                    filter: {
                        livingSituation: ['house', 'flat'],
                        energyType: ['electricity'],
                        location: ['decentralized'],
                        purpose: ['room'],
                        cost: ['low'],
                        isolation: ['good']
                    },
                    text: "Eine Infrarotheizung gibt Strahlung im Infrarotbereich von sich. Trifft diese Strahlung auf Gegenstände, geben diese Wärme ab. Dadurch werden kleine Bereiche des Raumes erwärmt. Infrarotheizungen sind hierbei relativ kostengünstig in der Anschaffung, werden aber mit Strom, dem teuersten Energieträger betrieben. Sie eignen sich deshalb als Zusatzheizungen für fest abgrenzbare Bereiche, wie zum Beispiel dem Home-Office Arbeitsplatz, den Essbereich, direkt am Sofa oder in sehr gut gedämmten Wohnungen. So kann die Raumtemperatur um ein-zwei Grad abgesenkt werden, ohne dass dabei Komforteinbußen zu befürchten sind. Sie eignen sich vor allem zum Nachrüsten für kleine Räume oder intensiv genutzte Bereiche.",
                    ups: ["kostengünstige Anschaffung", "effiziente Energieumwandlung", "Erhöhung des Raumkomfort"],
                    downs: ["hohe Energiekosten", "geringer Abstand zwischen Heizung und Möbel benötigt", "großflächig nur bei sehr gut gedämmten Räumen praktikabel"]
                },
                {
                    id: "solarthermie",
                    name: "Solarthermie",
                    image: "solar.jpg",
                    image_src: "https://www.flickr.com/photos/klimaundenergiemodellregionen/49162811828",
                    filter: {
                        livingSituation: ['house'],
                        energyType: ['environment', 'electricity'],
                        location: ['central'],
                        purpose: ['room', 'water'],
                        cost: ['high'],
                        isolation: ['good']
                    },
                    text: "Die Sonne erhitzt Gegenstände, wenn sie scheint. Diese einfache Erkenntnis wird in der Solarthermie genutzt. Es werden Platten oder Rohre verlegt, welche mit einer Flüssigkeit durchspült werden. Schon eine relativ geringe Dachfläche reicht aus, um den Heizenergiebedarf zu decken. Dabei sollte die Planung und Errichtung durch einen Fachmann geschehen, da hier ein zweiter Heizungskreislauf geplant und errichtet wird. Es gibt verschiedene Arten von Sonnenkollektoren (Flach-, Röhren-, Vakuumröhrenkollektoren, Heat-Pipes und andere), die sich in Kosten, Vorlauftemperatur, Effizienz und Wartungsaufwand unterscheiden.",
                    ups: ["kostengünstiger Betrieb", "geringer Flächenbedarf", "kostengünstige Deckung der Grundlast"],
                    downs: ["hoher Installations- und Planungsaufwand", "Zuheizung im Winter notwendig"]
                },
                {
                    id: "heizstab",
                    name: "Heizstab",
                    image: "heizstab.jpg",
                    image_src: "https://www.photovoltaik4all.de/heizstab-fuer-fronius-ohmpilot-3-8-kw",
                    filter: {
                        livingSituation: ['house', 'flat'],
                        energyType: ['electricity'],
                        location: ['central'],
                        purpose: ['room', 'water'],
                        cost: ['low'],
                        isolation: ['good', 'bad']
                    },
                    text: "Ein Heizstab erwärmt Wasser im Heizkreislauf durch elektrische Energie. Ein Heizstab eignet sich vor allem zur Zuheizung einer anderen Hauptheizung, wenn diese die Heizlast nicht mehr stemmen kann. Dies kommt typischerweise bei volatilen Umweltenergien wie bei einer Wärmepumpe oder einer Solarthermieanlage vor. Dabei ist der Heizstab vergleichsweise billig, weshalb er auch noch in Zukunft oftmals verbaut werden dürfte. Auch wäre es denkbar, dass der Heizstab zuheizt, wenn Strom aus einer eigenen Photovoltaikanlage erzeugt wird.",
                    ups: ["einfache Installation"],
                    downs: ["kein Alleinbetrieb ratsam", "teurer Betrieb mit zugekauften Strom"]
                },
                {
                    id: "lu-wa-waermepumpe",
                    name: "Luft-Wasser-Wärmepumpe",
                    image: "lu-wa-waermepumpe.jpg",
                    image_src: "https://www.buderus.de/de/produkte/catalogue/buderus-produkte-fur-ihr-haus/warmeerzeuger/warmepumpe/157672_logatherm-wsw186it",
                    filter: {
                        livingSituation: ['house'],
                        energyType: ['environment'],
                        location: ['central'],
                        purpose: ['room', 'water'],
                        cost: ['high'],
                        isolation: ['good']
                    },
                    text: "Eine Wärmepumpe entzieht der Umgebung Wärme und hebt diese durch einen technischen Prozess auf ein höheres Energieniveau. Die entzogene Wärme wird durch einen Carnot-Prozess „konzentriert“. Das Prinzip gleicht dem eines Kühlschranks, nur dass hier ein Trägermedium erwärmt statt abgekühlt wird. Wärmepumpen werden durch das Medium aus dem sie die Energie ziehen und an welches sie die Wärme abgeben, unterschieden. Wärmepumpen benötigen elektrische Energie, geben aber mehr Wärme ab, als sie durch den Betrieb an elektrischer Energie beziehen. Das Verhältnis zwischen eingesetzter elektrischer Energie und gewonnener Wärme wird als Jahresarbeitszahl bezeichnet. Diese liegt je nach Gerätetyp und Aufstellort bei 3 bis 5. Man kann also aus einer kWh elektrischer Energie drei bis fünf kWh Wärme ziehen. Diese Differenz wird aus der Umgebung gezogen. Die Jahresarbeitszahl (JAZ) gibt das Verhältnis zwischen eingesetzter Energie und Wärmegewinn über einen Zeitraum von einem Jahr wieder.<br/><br/>Eine Luft-Wasser-Wärmepumpe zieht die Wärme aus der Außenluft und übergibt diese an das Medium Wasser. Das Problem ist, dass diese Art der Wärmepumpe eine Luftzufuhr benötigt. Diese Zuluft muss in einem gewissen Temperaturbereich liegen, um die Energie aus dieser ziehen können. Je höher die Temperaturdifferenz zwischen Umgebung und der Vorlauftemperatur ist, desto geringer die Effizienz. Es kann also passieren, dass an besonders kalten Tagen eine Zuheizung benötigt wird, um die notwendige Wärme bereitstellen zu können. Wird mit der Wärmepumpe nur Warmwasser bereitgestellt, spricht man auch von einer Brauchwasserwärmepumpe. Die Planung und Installation ist abhängig von dem bereits verbauten System und den örtlichen Begebenheiten. Dies sollte deshalb durch einen Fachmann geschehen.",
                    ups: ["Möglichkeit der Wärmerückgewinnung", "geringe Betriebskosten"],
                    downs: ["nicht für Alleinbetrieb geeignet", "je nach Aufstellort Luftzufuhr von außen benötigt"]
                },
                {
                    id: "split-klima",
                    name: "Split-Klimagerät",
                    image: "split-klima.jpg",
                    image_src: "https://commons.wikimedia.org/w/index.php?curid=19696849",
                    filter: {
                        livingSituation: ['house'],
                        energyType: ['environment'],
                        location: ['decentralized'],
                        purpose: ['room'],
                        cost: ['high'],
                        isolation: ['good']
                    },
                    text: "Eine Wärmepumpe entzieht der Umgebung Wärme und hebt diese durch einen technischen Prozess auf ein höheres Energieniveau. Die entzogene Wärme wird durch einen Carnot-Prozess „konzentriert“. Das Prinzip gleicht dem eines Kühlschranks, nur dass hier ein Trägermedium erwärmt statt abgekühlt wird. Wärmepumpen werden durch das Medium aus dem sie die Energie ziehen und an welches sie die Wärme abgeben, unterschieden. Wärmepumpen benötigen elektrische Energie, geben aber mehr Wärme ab, als sie durch den Betrieb an elektrischer Energie beziehen. Das Verhältnis zwischen eingesetzter elektrischer Energie und gewonnener Wärme wird als Jahresarbeitszahl bezeichnet. Diese liegt je nach Gerätetyp und Aufstellort bei 3 bis 5. Man kann also aus einer kWh elektrischer Energie drei bis fünf kWh Wärme ziehen. Diese Differenz wird aus der Umgebung gezogen. Die Jahresarbeitszahl (JAZ) gibt das Verhältnis zwischen eingesetzter Energie und Wärmegewinn über einen Zeitraum von einem Jahr wieder.<br/><br/>Eine Split-Klimagerät entzieht der Zuluft Wärme und gibt sie an die Umgebung ab. Wenn das Klimagerät über einen reversiblen Betrieb verfügt, kann das Gerät über dieses Prinzip auch heizen. Dabei werden die \"warme\" und die \"kalte\" Seite einfach getauscht. Die Anlage entzieht dann der Außenluft Wärme und gibt sie an der Raumluft weiter. Dabei muss die Außentemperatur in einem gewissen Bereich liegen, damit eine Zuheizung möglich ist. Dieser Temperaturbereich ist Geräteabhängig.",
                    ups: ["Möglichkeit der Wärmerückgewinnung", "geringe Betriebskosten"],
                    downs: ["geringe Vorlauftemperatur", "Abluftanlage notwendig"]
                },
                {
                    id: "pellet",
                    name: "Holzpellet",
                    image: "pellet.jpg",
                    image_src: "https://commons.wikimedia.org/wiki/File:Holzpellets.jpg",
                    filter: {
                        livingSituation: ['house'],
                        energyType: ['wood'],
                        location: ['central'],
                        purpose: ['room', 'water'],
                        cost: ['high'],
                        isolation: ['good', 'bad']
                    },
                    text: "Der Brennstoff „Holzpellets“ besteht aus verpressten und getrockneten Spänen und Hobelabfällen aus der Holzindustrie. Die Pellets werden in einem trockenen und gut belüfteten Raum (überwiegend im Keller) untergebracht und durch eine Förderschnecke in den Brennraum geschafft. Der Lagerraum sollte in regelmäßigen Abständen (meist jährlich) gereinigt werden. Auch muss die Asche regelmäßig (je nach System und Nutzung) entsorgt werden. Der Energiegehalt der Pellets beträgt mindestens 4,5 kWh pro kg. Dieser kann aber auch bei entsprechender Trocknung und Qualität höher liegen. Es werden jedoch nicht nur Holzpellets, sondern auch Stroh- oder Torfpellets auf dem Markt angeboten. Sie besitzen jedoch eine andere chemische Zusammensetzung, was die Lebenszeit der Anlage verkürzen kann. Dies ist anlagenspezifisch. Pelletheizungen werden vorwiegend in Ein- oder Mehrfamilienhäuser genutzt.",
                    ups: ["automatisierte Verbrennung möglich", "hoher, standardisierter Brennwert"],
                    downs: ["Reinigungsaufwand", "Lagerraum für Brennstoff notwendig"]
                },
                {
                    id: "scheite",
                    name: "Holzscheite",
                    image: "scheite.jpg",
                    image_src: "https://commons.wikimedia.org/w/index.php?curid=7863026",
                    filter: {
                        livingSituation: ['house'],
                        energyType: ['wood'],
                        location: ['central'],
                        purpose: ['room', 'water'],
                        cost: ['high'],
                        isolation: ['good', 'bad']
                    },
                    text: "Hackschnitzel für eine Verfeuerung in privaten Anlagen bestehen aus Waldholzhackschnitzel. Diese Hackschnitzel bestehen aus Ästen, welche nicht zu einem anderen Produkt verarbeitet werden können oder aus Holz aus Landschaftspflegemaßnahmen. Sie werden meist lose oder in Bigbags angeliefert. Auch sie benötigen einen trockenen und gut durchlüfteten Lagerraum. Es sollte darauf geachtet werden, dass die Holzhackschnitzel den Anforderungen der Anlage entsprechen. So hat die Größe der Holzhackschnitzel einen Einfluss auf den Transport zur Anlage. Sind die Hackschnitzel zu klein können sie die Förderschnecke verstopfen. Meist sind Hackschnitzelanlagen in Mehrfamilienhäuser zu finden. Die Anlagen besitzen eine Leistung ab 20 kW. ",
                    ups: ["automatisierte Verbrennung möglich", "standardisierter Brennwert"],
                    downs: ["Reinigungsaufwand", "Lagerraum für Brennstoff notwendig"]
                },
                {
                    id: "hackschnitzel",
                    name: "Holzhackschnitzel",
                    image: "hackschnitzel.jpg",
                    image_src: "https://commons.wikimedia.org/w/index.php?curid=3352954",
                    filter: {
                        livingSituation: ['house'],
                        energyType: ['wood'],
                        location: ['central'],
                        purpose: ['room', 'water'],
                        cost: ['high'],
                        isolation: ['good', 'bad']
                    },
                    text: "Hackschnitzel für ein Verfeuerung in privaten Anlagen bestehen aus Waldholzhackschnitzel. Diese Hackschnitzel bestehen aus Ästen, welche nicht zu einem anderen Produkt verarbeitet werden können oder aus Holz aus Landschaftspflegemaßnahmen. Sie werden meist lose oder in Bigbags angeliefert. Auch sie benötigen einen trockenen und gut durchlüfteten Lagerraum. Es sollte darauf geachtet werden, dass die Holzhackschnitzel den Anforderungen der Anlage entsprechen. So hat die Größe der Holzhackschnitzel einen Einfluss auf den Transport zur Anlage. Sind die Hackschnitzel zu klein können sie die Förderschnecke verstopfen. Meist sind Hackschnitzelanlagen in Mehrfamilienhäuser zu finden. Die Anlagen besitzen eine Leistung ab 20 kW.",
                    ups: ["automatisierte Verbrennung möglich", "standardisierter Brennwert"],
                    downs: ["Reinigungsaufwand", "Lagerraum für Brennstoff notwendig"]
                }
            ].sort((a, b) => a.name.localeCompare(b.name)),
            filter: {
                livingSituation: 'all',
                energyType: 'all',
                location: 'all',
                purpose: 'all',
                cost: 'all',
                isolation: 'all'
            },
            filterBarIsOpened: false
        }
    },
    mutations: {
        filter: (state, d) => {
            state.filter = d;
        },
        filterBarIsOpened: (state, d) => {
            state.filterBarIsOpened = d;
        }
    }
})
